import React from 'react';
import { withTranslation } from 'react-i18next';
import ListManual from './ListManual';
import './manuals.css';

function Api({ t }) {
    const manuals = [
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/EbntF6o3LsNDniHRDaCQp0sBTtwzk_hZYRqQdXyYX_PS7g?e=runeI6",
            "title": t('manuals.api.vidSignerApi'),
            "description": t('manuals.api.vidSignerApiInfo')
        },
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/ERTJC77wMLNDqRGEAKpWV0QBxbJ8tsdNc74t-sDQic8hTg?e=HbOgpl",
            "title": t('manuals.api.vidCloudMngRestServices'),
            "description": t('manuals.api.vidCloudMngRestServicesInfo')
        }
    ];

    return <ListManual links={manuals} />;
}

export default withTranslation()(Api);
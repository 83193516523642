import React from 'react';
import Image from 'react-bootstrap/Image';
import Navbar from 'react-bootstrap/Navbar';

function NavBarBrand(props) {
    return (
        <Navbar.Brand href={props.href}>
            <Image
                src="/img/NavBrandValidatedIdLogo.png"
                width="150"
                height="30"
                className="d-inline-block"
                rounded
            />
        </Navbar.Brand>
    );
}

export default NavBarBrand;

import React, { Component } from 'react';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ListManual from './Manuals/ListManual';

class Examples extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlRedirect: "/",
            redirect: false,
            isLoading: false
        };
    }

    componentDidMount() {
        var _this = this;
        this.setState({ isLoading: true });

        axios({
            method: 'GET',
            url: 'api/User/IsUserActiveSession',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                _this.setState({ isLoading: false });
            })
            .catch(function (error) {
                _this.setState({ redirect: true });
            })
    }

    renderMainLogin() {
        const { t } = this.props;
        const examples = [
            {
                "url": "https://vidsigner.sharepoint.com/:u:/s/PublicAssets/EYXNMqWuGm1KmpnShRELUzoBpvf04R7piIPhJ01lihtFcQ?e=pdQD1F&download=1",
                "title": t('manuals.api.jsonExample'),
                "description": t('manuals.api.vidSignerApiInfo')
            },
            {
                "url": "https://vidsigner.sharepoint.com/:u:/s/PublicAssets/Ed2kuDvSp3xIm8KLp3XLGZUB4Y3do51yptEfFhW_Yv6EKQ?e=owV6tE&download=1",
                "title": t('manuals.api.phpExample'),
                "description": t('manuals.api.vidCloudMngRestServicesInfo')
            },
            {
                "url": "https://vidsigner.sharepoint.com/:u:/s/PublicAssets/EdL5YCgMVlRJokwJXVsWJlkBeghp9SgDko4e-gdr6BvGPQ?e=lZTQ8r&download=1",
                "title": t('manuals.api.javaExample'),
                "description": t('manuals.api.vidSignerApiInfo')
            },
            {
                "url": "https://vidsigner.sharepoint.com/:u:/s/PublicAssets/EXxbHIJd-29Nn11CPNeez38BPQ3z5eVGSjbkUuaADBEurg?e=08VZIM&download=1",
                "title": t('manuals.api.netExample'),
                "description": t('manuals.api.vidCloudMngRestServicesInfo')
            }
        ];
        return (
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link className="green-background" eventKey="1">{t('examples.apiIntegration')}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="1">
                                <ListManual links={examples} />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        );
    }

    renderRedirect() {
        return <Redirect to={{ pathname: this.state.urlRedirect }} />;
    }

    render() {
        const { redirect, isLoading } = this.state;

        return (
            <>
                {redirect && this.renderRedirect()}
                {isLoading === true &&
                    <div className="text-center mt-5">
                        <Spinner animation="border" role="status" className="green-color">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                }
                {isLoading === false &&
                    redirect === false &&
                    this.renderMainLogin()
                }
            </>
        );
    }
}

export default withTranslation()(Examples);
import React, { useState } from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Logout from './Logout';
import NavBarBrand from './NavBarBrand';
import NavDropdownLanguage from './NavDropdownLanguage';
import ZenDesk from './ZenDesk';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { withTranslation } from 'react-i18next';


function NavBarAdmin({ t }) {
    const defaultLang = localStorage.getItem('i18nextLng');
    const [languaje, setLanguaje] = useState(defaultLang);

    const changeLang = (lang) => {
        setLanguaje(lang);
    }

    const [showModal, setShowModal] = useState(false);
    const handleClose = () => setShowModal(false);
    const handleOpenModal = () => setShowModal(true);

    return (
        <>
            <Navbar bg="light" variant="light" className="green-background" collapseOnSelect expand="sm">
                <NavBarBrand href="/admin" />
                <Navbar.Toggle />
                <Navbar.Collapse id="navbar-admin">
                    <Nav.Item>
                        <a className="color-a" href='https://vidsigner.sharepoint.com/:u:/s/PublicAssets/EUTqgIC0b1JGjNdDWZMDS3IBt_qBzXOXL-yzYshM5pQmuQ?e=kk1sRY&download=1' download="openApi.yaml" onClick={handleOpenModal}>Open API</a>
                    </Nav.Item>
                    <Nav className="ml-auto">
                        <NavDropdownLanguage changeLang={changeLang} />
                        <Logout />
                        <ZenDesk languaje={languaje} />
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
            <Modal show={showModal} animation={false} centered size="md" onHide={() => handleClose()} backdrop="static">
                <Modal.Header>
                    <Modal.Title>{t('general.attention')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>{t('general.downloadedFile')}!!!</p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="grey-button" onClick={handleClose}>{t('general.closeLabel')}</Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default withTranslation()(NavBarAdmin);

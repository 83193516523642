import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';

function ListManual(props) {
    const links = props.links;

    const items = links.map((item, index) => (
        <ListGroup.Item
            key={index}
            as="a"
            href={item.url}
            target="_blank"
            className="grey-color"
        >
            <strong>{item.title}</strong>
            {item.description !== null ? item.description : "" }
        </ListGroup.Item>
    ));

    return (
        <ListGroup> {items} </ListGroup>
    );
}

export default ListManual;
import React, { Component, Suspense } from 'react';
import { Route } from 'react-router';
import Admin from './components/Admin';
import Home from './components/Home';
import Login from './components/Login';
import RecoverAccount from './components/RecoverAccount';

class App extends Component {

    render() {

        const Loader = () => {
            return <div>Loading...</div>;
        };

        return (
            <Suspense fallback={<Loader />}>
                <div className="container">
                    <Route exact path='/' component={Login} />
                    <Route path='/login/recover' component={RecoverAccount} />
                    <Route path='/home' component={Home} />
                    <Route path='/admin' component={Admin} />
                </div>
            </Suspense>
        );
    }
}

export default App;
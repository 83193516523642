import React from 'react';
import { Route } from 'react-router';
import Examples from './Examples';
import { Layout } from './Layout';
import Manuals from './Manuals/ManualTabs';
import Profile from './Profile';
import Swagger from './Swagger';

function Home() {
    return (
        <>
            <Layout>
                <Route path='/home' exact component={Swagger} />
                <Route path='/home/manuals' component={Manuals} />
                <Route path='/home/examples' component={Examples} />
                <Route path='/home/profile' component={Profile} />
            </Layout>
        </>
    );
}

export default Home;


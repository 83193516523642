import axios from 'axios';
import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import Table from 'react-bootstrap/Table';
import { withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';

class Profile extends Component {
    displayName = Profile.name

    constructor(props) {
        super(props);
        this.state = {
            email: "",
            isResetPasswordEnabled: false,
            isNotValidForm: false,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            subscriptionName: "",
            isSubscriptionPassHidden: true,
            subscriptionPass: "",
            enrollmentCode: "",
            isViDMobileUserOk: true,
            viDMobileUserEmail: "",
            viDMobileUserName: "",
            viDMobileUserLegalId: "",
            viDMobileUserDomain: "",
            viDMobileUserPass: "Demo1234#",
            isVidMobilUserPassHidden: true,
            stamperCertificates: [],
            viDMobileUserCertificates: [],
            successMsg: "",
            errorMsg: "",
            loading: false,
            redirectToLogin: false
        };

        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleResetPassword = this.handleResetPassword.bind(this);
        this.resetPasswordSubmit = this.resetPasswordSubmit.bind(this);
        this.cleanVariables = this.cleanVariables.bind(this);
        this.getProfile = this.getProfile.bind(this);
    }

    componentDidMount() {
        this.getProfile();
    }

    getProfile() {
        var _this = this;

        _this.setState({ loading: true });

        axios({
            method: 'GET',
            url: 'api/User/GetProfile',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                var email = response.data.email;
                var subscription = response.data.subscription;
                var stamperCertificates = response.data.stamperCertificates;
                var vidMobileUser = response.data.vidMobileUser;
                var vidMobileUserCertificates = response.data.vidMobileUserCertificates;

                if (vidMobileUser !== null) {
                    _this.setState({
                        viDMobileUserEmail: vidMobileUser.viDMobileUserEmail,
                        viDMobileUserName: vidMobileUser.viDMobileUserName,
                        viDMobileUserLegalId: vidMobileUser.viDMobileUserLegalId,
                        viDMobileUserDomain: subscription.signatureChannels.viDMobile.domain,
                        viDMobileUserCertificates: vidMobileUserCertificates
                    });
                }
                else {
                    _this.setState({
                        isViDMobileUserOk: false,
                        viDMobileUserCertificates: null
                    });
                }

                _this.setState({
                    email: email,
                    subscriptionName: subscription.subscriptionName,
                    subscriptionPass: subscription.subscriptionPass,
                    enrollmentCode: subscription.enrollmentCode,
                    stamperCertificates: stamperCertificates,
                    loading: false
                });

            })
            .catch(function (error) {
                if (error.response.status == 401) { // It's not authorized. It goes to login
                    _this.setState({ redirectToLogin: true, isLoading: false });
                    return;
                }
                _this.setState({
                    errorMsg: error.message,
                    loading: false
                });
            });
    }

    handleDismissAlert() {
        window.setTimeout(() => {
            this.setState({ successMsg: "" });
        }, 3000);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState({
            [name]: value
        });
    }

    cleanVariables() {
        this.setState({
            isResetPasswordEnabled: false,
            isNotValidForm: false,
            currentPassword: "",
            newPassword: "",
            confirmPassword: "",
            successMsg: "",
            errorMsg: ""
        });
    }

    resetPasswordSubmit(event) {
        var _this = this;
        event.preventDefault();

        if (_this.state.newPassword !== _this.state.confirmPassword) {
            _this.setState({
                isNotValidForm: true,
                confirmPassword: ""
            });
            event.stopPropagation();
            return;
        }

        axios({
            method: 'PUT',
            url: 'api/User/UpdatePassword',
            params: {
                currentPassword: _this.state.currentPassword,
                newPassword: _this.state.newPassword
            },
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                _this.cleanVariables();

                _this.setState({
                    successMsg: _this.props.t('profile.msgUpdatePasswordSuccessflly')
                }, _this.handleDismissAlert);
            })
            .catch(function (error) {
                if (error.response.status == 401) { // It's not authorized. It goes to login
                    _this.setState({ redirectToLogin: true, isLoading: false });
                    return;
                }
                if (error.response.status === 400) {
                    error.message = _this.props.t('profile.msgCurrentPasswordError');
                }

                _this.setState({
                    errorMsg: error.message
                });
            });
    }

    handleResetPassword() {
        this.setState({
            isResetPasswordEnabled: true
        });
    }

    renderResetPassword() {
        const { t } = this.props;

        return (
            <>
                {
                    this.state.errorMsg !== "" &&
                    <Alert variant={'danger'} className="mt-2">
                        {this.state.errorMsg}
                    </Alert>
                }
                < hr />
                <Form validated={this.state.isNotValidForm} onSubmit={this.resetPasswordSubmit}>
                    <Form.Group as={Row}>
                        <Form.Label column sm={{ span: 2, offset: 4 }}>
                            {t('profile.currentPassword')}
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control type="password" name="currentPassword" onChange={this.handleInputChange} required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={{ span: 2, offset: 4 }}>
                            {t('profile.newPassword')}
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control type="password" name="newPassword" onChange={this.handleInputChange} required />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row}>
                        <Form.Label column sm={{ span: 2, offset: 4 }}>
                            {t('profile.confirmPassword')}
                        </Form.Label>
                        <Col sm="3">
                            <Form.Control type="password" name="confirmPassword" value={this.state.confirmPassword} onChange={this.handleInputChange} required />
                            <Form.Control.Feedback type="invalid">
                                {t('profile.passwordsAreDifferente')}
                            </Form.Control.Feedback>
                        </Col>
                    </Form.Group>
                    <Row>
                        <Col sm={{ span: 8, offset: 4 }} className="text-center">
                            <Button type="button" className="grey-button mr-2" onClick={this.cleanVariables}>{t('general.cancelLabel')}</Button>
                            <Button type="submit" className="green-button">{t('general.submitLabel')}</Button>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }

    renderDeveloperSiteSection() {
        const { successMsg } = this.state;
        const { t } = this.props;

        return (
            <>
                <h5 className="text-center">{t('profile.devSiteUserTitle')}</h5>
                <Row>
                    <Col sm="4">
                        <label>UserName</label>
                    </Col>
                    <Col sm="8">
                        <label>{this.state.email}</label>
                    </Col>
                </Row>

                {this.state.isResetPasswordEnabled
                    ? this.renderResetPassword()
                    :
                    <Row>
                        <Col sm="4">
                            <label>Password</label>
                        </Col>
                        <Col sm="8">
                            <Button variant="link" className="grey-color pl-0" onClick={this.handleResetPassword}>{t('general.editLabel')}</Button>
                        </Col>
                    </Row>
                }
                {
                    successMsg !== "" &&
                    <Alert variant={'success'} className="mt-2">
                        {successMsg}
                    </Alert>
                }
            </>
        );
    }

    renderSubscriptionSection() {
        const { t } = this.props;

        const toggleShow = () => {
            this.setState({ isSubscriptionPassHidden: !this.state.isSubscriptionPassHidden });
        };

        return (
            <>
                <h5 className="text-center">{t('profile.subscriptionTitle')}</h5>
                <Row>
                    <Col sm="4">
                        <label>SubscriptionName</label>
                    </Col>
                    <Col sm="8">
                        <label> {this.state.subscriptionName}</label>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <label>SubscriptionPass</label>
                    </Col>
                    <Col xs="12" sm="8" lg="5">
                        <InputGroup>
                            <Form.Control type={this.state.isSubscriptionPassHidden ? "password" : "text"}
                                value={this.state.subscriptionPass}
                                disabled
                            />
                            <InputGroup.Prepend>
                                <Button variant="outline-secondary" className="hidden-password" onClick={toggleShow}>Show/Hide</Button>
                            </InputGroup.Prepend>
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <label>EnrollmentCode</label>
                    </Col>
                    <Col sm="8">
                        <label> {this.state.enrollmentCode}</label>
                    </Col>
                </Row>
            </>
        );
    }

    renderCentralizedUserSection() {
        const { t } = this.props;

        const toggleShowVidMobilUserPass = () => {
            this.setState({ isVidMobilUserPassHidden: !this.state.isVidMobilUserPassHidden });
        };

        return (
            <>
                <h5 className="text-center">{t('profile.centralizedTitle')}</h5>
                <Row>
                    <Col sm="4">
                        <label>ViDMobileUserEmail</label>
                    </Col>
                    <Col sm="8">
                        <label> {this.state.viDMobileUserEmail}</label>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <label>ViDMobileUserLegalId</label>
                    </Col>
                    <Col sm="8">
                        <label> {this.state.viDMobileUserLegalId}</label>
                    </Col>
                </Row>
                <Row>
                    <Col sm="4">
                        <label>ViDMobileUserPass</label>
                    </Col>
                    <Col xs="12" sm="8" lg="5">
                        <InputGroup>
                            <Form.Control type={this.state.isVidMobilUserPassHidden ? "password" : "text"}
                                value={this.state.viDMobileUserPass}
                                disabled
                            />
                            <InputGroup.Prepend>
                                <Button variant="outline-secondary" className="hidden-password" onClick={toggleShowVidMobilUserPass}>Show/Hide</Button>
                            </InputGroup.Prepend>
                        </InputGroup>
                        <Form.Text className="text-muted">
                            {t('profile.viDMobileUserPassHelper')}
                        </Form.Text>
                    </Col>
                </Row>
                <hr />
            </>
        );
    }

    renderCentralizedCertificatesSection() {
        const { t } = this.props;

        return (
            <>
                <h5 className="text-center">{t('profile.centralizedCertificatesTitle')}</h5>
                {this.state.viDMobileUserCertificates.length === 0
                    ? <p>
                        {t('profile.msgNoCentralizedCertificates')}
                        &nbsp;<a href="https://console.vidsigner.net/consolesign.html" target="_blank" rel="noopener noreferrer">CENTRALIZED web</a>&nbsp;
                        {t('profile.msgNoCentralizedCertificatesContinue')}
                      </p>
                    :
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>Alias</th>
                                <th>GUID</th>
                                <th>{t('profile.centralizedTableType')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.viDMobileUserCertificates.map((certificate, index) =>
                                (
                                    <tr key={certificate.guid}>
                                        <td>{index}</td>
                                        <td>{certificate.certalias}</td>
                                        <td>{certificate.guid}</td>
                                        <td>{certificate.certType}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                }
                <hr />
            </>
        );
    }

    renderStamperCertificatesSection() {
        const { t } = this.props;

        return (
            <>
                <h5 className="text-center">{t('profile.stamperCertificatesTitle')}</h5>
                {this.state.stamperCertificates.length === 0
                    ? <p>{t('profile.msgNoStamperCertificates')}</p>
                    :
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>{t('profile.stamperTableDescription')}</th>
                                <th>GUID</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.stamperCertificates.map((stamper, index) =>
                                (
                                    <tr key={stamper.certificateGUID}>
                                        <td>{index}</td>
                                        <td>{stamper.description}</td>
                                        <td>{stamper.certificateGUID}</td>
                                    </tr>
                                )
                            )}
                        </tbody>
                    </Table>
                }
            </>
        );
    }

    renderMain() {
        const { t } = this.props;

        return (
            <>
                {this.renderDeveloperSiteSection()}
                <hr />
                {this.renderSubscriptionSection()}
                <hr />
                {this.state.isViDMobileUserOk && this.renderCentralizedUserSection()}
                {this.state.viDMobileUserCertificates !== null && this.renderCentralizedCertificatesSection()}
                {this.state.stamperCertificates !== null && this.renderStamperCertificatesSection()}
                <br />
                <p>{t('profile.defaultCertificatePin')}</p>
            </>
        );
    }

    render() {
        return (
            <>
                {this.state.redirectToLogin && <Redirect to={{ pathname: "/" }} />}
                {this.state.loading === true &&
                    <div className="text-center mt-5">
                        <Spinner animation="border" role="status" className="green-color">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                }
                {this.state.loading === false &&
                    this.renderMain()
                }
            </>
        );
    }
}

export default withTranslation()(Profile);
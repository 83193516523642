import axios from 'axios';
import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { withTranslation } from 'react-i18next';

class DeleteUserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            errorMsg: null
        };

        this.deleteUser = this.deleteUser.bind(this);
    }

    deleteUser() {
        var _this = this;

        _this.setState({ isLoading: true });

        axios({
            method: 'DELETE',
            url: 'api/User',
            params: { "id": _this.props.userId },
            contentType: 'application/json'
        })
            .then(function (response) {
                _this.props.submit(response.data);
            })
            .catch(function (error) {
                _this.setState({ errorMsg: error.message, isLoading: false });
            });
    }

    render() {
        const { isLoading, errorMsg } = this.state;
        const { t, close } = this.props;

        return (
            <Modal show animation={false} centered size="lg" onHide={() => this.props.close()}>
                <Modal.Header>
                    {
                        (errorMsg !== null && errorMsg !== "")
                            ?
                            <Alert variant={'danger'}>
                                {errorMsg}
                            </Alert>
                            :
                            isLoading
                                ?
                                <p><em>{t('user.msgDeletingUser')}</em></p>
                                :
                                <Modal.Title>{t('user.modalDeleteTitle')}</Modal.Title>
                    }

                </Modal.Header>
                <Modal.Footer>
                    {!isLoading &&
                        <>
                            <Button className="grey-button" onClick={close}>{t('general.closeLabel')}</Button>

                            {
                                (errorMsg === null || errorMsg === "")
                                && <Button className="green-button" onClick={this.deleteUser}>{t('general.confirmLabel')}</Button>
                            }
                        </>
                    }
                </Modal.Footer>
            </Modal>
        );
    }
}

export default withTranslation()(DeleteUserModal);


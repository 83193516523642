import axios from 'axios';
import React, { Component } from 'react';
import ResponsiveEmbed from 'react-bootstrap/ResponsiveEmbed';
import Spinner from 'react-bootstrap/Spinner';
import { Redirect } from 'react-router-dom';

class Swagger extends Component {
    constructor(props) {
        super(props);
        this.state = {
            subscriptionCredentialsBase64: "",
            errorMsg: "",
            loading: false,
            redirectToLogin: false
        };

        this.getProfile = this.getSubscriptionCredentials.bind(this);
    }

    componentDidMount() {
        this.getSubscriptionCredentials();
    }

    getSubscriptionCredentials() {
        var _this = this;

        _this.setState({ loading: true });

        axios({
            method: 'GET',
            url: 'api/User/GetSubscriptionCredentials',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {

                _this.setState({
                    subscriptionCredentialsBase64: response.data,
                    loading: false
                });

            })
            .catch(function (error) {
                if (error.response.status == 401) { // It's not authorized. It goes to login
                    _this.setState({ redirectToLogin: true, isLoading: false });
                    return;
                }
                _this.setState({
                    errorMsg: error.message,
                    loading: false
                });
            });
    }

    render() {
        return (
            <>
                {this.state.redirectToLogin && <Redirect to={{ pathname: "/" }} />}
                {this.state.loading === true &&
                    <div className="text-center mt-5">
                        <Spinner animation="border" role="status" className="green-color">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                }
                {this.state.loading === false &&
                    this.state.subscriptionCredentialsBase64 !== "" &&
                    <ResponsiveEmbed aspect="a16by9">
                        <embed src={"https://pre-vidsignercloud.validatedid.com/swagger/ui/index?swaggertoken=" + this.state.subscriptionCredentialsBase64} />
                    </ResponsiveEmbed>
                }
            </>
        );
    }
}

export default Swagger;
import axios from 'axios';
import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import DropdownLanguage from './DropdownLanguage';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            redirect: false,
            user: null,
            error: null,
            loading: false,
            urlRedirect: ""
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleUserInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleSubmit(e) {
        e.preventDefault();

        var _this = this;

        _this.setState({ loading: true });

        axios({
            method: 'POST',
            url: 'api/Login',
            data: {
                email: this.state.email,
                password: this.state.password
            },
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                _this.setState({ redirect: true, urlRedirect: response.data.redirect });
            })
            .catch(function (error) {
                _this.setState({ error: error.message, loading: false });
            });
    }

    renderMainLogin() {
        const { error } = this.state;
        const { t } = this.props;

        return (
            <>
                <Row className="mt-2">
                    <Col sm={{ span: 4, offset: 4 }}>
                        <DropdownLanguage />
                    </Col>
                </Row>
                <Row className="mb-5 mt-5 text-center">
                    <Col sm={{ span: 4, offset: 4 }}>
                        <Image src="/img/ValidatedIdLogo.png" rounded />
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 4, offset: 4 }}>
                        {
                            error !== null &&
                            <Alert variant={'danger'} className="mb-2 mt-2">
                                {t('login.error')}
                            </Alert>
                        }

                        <Form onSubmit={this.handleSubmit} >
                            <Form.Group controlId="formEmail">
                                <Form.Label>{t("login.emailTitle")}</Form.Label>
                                <Form.Control type="email" placeholder={t('login.emailPlaceholder')} value={this.state.email} name="email" onChange={this.handleUserInput} />
                            </Form.Group>

                            <Form.Group controlId="formPassword">
                                <Form.Label>{t('login.passwordTitle')}</Form.Label>
                                <Form.Control type="password" placeholder={t('login.passwordPlaceholder')} value={this.state.password} name="password" onChange={this.handleUserInput} />
                            </Form.Group>

                            <Row className="mb-4">
                                <Col>
                                    <Button type="submit" variant="primary" className="green-button" block>{t('login.button')}</Button>
                                </Col>
                            </Row>
                            <Row className="text-center">
                                <Col>
                                    <Link to={"/login/recover"}>{t('login.forgottenAccount')}</Link>
                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }

    renderRedirect() {
        const { urlRedirect } = this.state;
        
        return <Redirect to={{ pathname: urlRedirect }} />;
    }

    render() {
        const { redirect, loading } = this.state;

        return (
            <>
                {redirect && this.renderRedirect()}
                {loading === true &&
                    <div className="text-center mt-5">
                        <Spinner animation="border" role="status" className="green-color">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                }
                {loading === false &&
                    redirect === false &&
                    this.renderMainLogin()
                }
            </>
        );
    }
}

export default withTranslation()(Login);
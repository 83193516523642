import axios from 'axios';
import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import { withTranslation } from 'react-i18next';
import { Link, Redirect } from 'react-router-dom';
import DropdownLanguage from './DropdownLanguage';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';

class RecoverAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            redirectToLogin: false,
            successMsg: null,
            error: null
        };

        this.handleUserInput = this.handleUserInput.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleLoginRedirect = this.handleLoginRedirect.bind(this);
    }

    handleUserInput(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    handleLoginRedirect() {
        window.setTimeout(() => {
            this.setState({ redirectToLogin: true });
        }, 5000);
    }

    handleSubmit(e) {
        e.preventDefault();

        var _this = this;

        _this.setState({
            errorMsg: ""
        });

        axios({
            method: 'POST',
            url: 'api/Login/RecoverAccount',
            params: {
                email: this.state.email
            },
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                _this.setState({
                    successMsg: _this.props.t('login.recoverPasswordSuccess') 
                }, _this.handleLoginRedirect);
            })
            .catch(function (error) {
                _this.setState({ error: _this.props.t('login.recoverPasswordError') });
            });
    }

    render() {
        const { error, successMsg, redirectToLogin } = this.state;
        const { t } = this.props;

        return (
            <>
                {redirectToLogin && <Redirect to="/" />}
                <Row className="mt-2">
                    <Col sm={{ span: 4, offset: 4 }}>
                        <DropdownLanguage />
                    </Col>
                </Row>
                <Row className="mb-5 mt-5 text-center">
                    <Col sm={{ span: 4, offset: 4 }}>
                        <Image src="/img/ValidatedIdLogo.png" rounded />
                    </Col>
                </Row>
                <Row>
                    <Col sm={{ span: 4, offset: 4 }}>
                        <h4>{t('login.recoverTitle')}</h4>
                        <hr />
                        {
                            successMsg !== null &&
                            <Alert variant={'success'} className="mt-2">
                                {successMsg}
                            </Alert>
                        }
                        {
                            error !== null &&
                            <Alert variant={'danger'} className="mt-2">
                                {error}
                            </Alert>
                        }
                        <Form onSubmit={this.handleSubmit} >
                            <Form.Group controlId="formEmail">
                                <Form.Label>{t("login.recoverEmailTitle")}</Form.Label>
                                <Form.Control type="email" placeholder={t('login.recoverEmailPlaceholder')} name="email" onChange={this.handleUserInput} required/>
                            </Form.Group>

                            <ButtonToolbar>
                                <Button type="submit" className="green-button">{t('login.recoverButton')}</Button>
                                <Link to="/" className="ml-2">
                                    <Button className="grey-button">
                                        <span>{t('general.cancelLabel')}</span>
                                    </Button>
                                </Link>
                            </ButtonToolbar>
                        </Form>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withTranslation()(RecoverAccount);
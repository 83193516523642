import axios from 'axios';
import React, { Component } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Table from 'react-bootstrap/Table';
import Card from 'react-bootstrap/Card';
import ButtonToolbar from 'react-bootstrap/ButtonToolbar';
import Spinner from 'react-bootstrap/Spinner';
import { withTranslation } from 'react-i18next';
import CreateUserModal from './User/CreateUserModal';
import DeleteUserModal from './User/DeleteUserModal';
import { Redirect } from 'react-router-dom';

class UserTable extends Component {
    static UserType = {
        admin: "Admin",
        user: "User"
    };

    constructor(props) {
        super(props);
        this.state = {
            users: [],
            currentUser: {},
            userToDeleteId: null,
            isLoading: false,
            showCreateModal: false,
            showDeleteModal: false,
            redirectToLogin: false,
            successMsg: "",
            errorMsg: ""
        };

        this.handleCreateUserModal = this.handleCreateUserModal.bind(this);
        this.handleCreateUserSubmit = this.handleCreateUserSubmit.bind(this);
        this.handleDeleteUserModal = this.handleDeleteUserModal.bind(this);
        this.handleDeleteUserSubmit = this.handleDeleteUserSubmit.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleDismissAlert = this.handleDismissAlert.bind(this);
    }

    componentDidMount() {
        var _this = this;

        _this.setState({ isLoading: true });

        axios({
            method: 'GET',
            url: 'api/User',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                response.data.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
                _this.setState({ isLoading: false, users: response.data });
            })
            .catch(function (error) {
                if (error.response.status == 401) { // It's not authorized. It goes to login
                    _this.setState({ redirectToLogin: true, isLoading: false });
                    return;
                }
                _this.setState({ errorMsg: error.message, isLoading: false });
            });
    }

    handleDismissAlert() {
        window.setTimeout(() => {
            this.setState({ successMsg: "" });
        }, 3000);
    }

    handleDeleteUserModal(userId) {
        this.setState({
            showDeleteModal: !this.state.showDeleteModal,
            userToDeleteId: userId
        });
    }

    handleResendWelcomeUserEmail(email) {
        var _this = this;

        _this.setState({ isLoading: true });

        axios({
            method: 'POST',
            params: {
                targetEmail: email
            },
            url: 'api/User/ResendWelcomeUserEmail',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                _this.setState({ isLoading: false, successMsg: response.data });
            })
            .catch(function (error) {
                _this.setState({ isLoading: false, errorMsg: error.message });
            });
    }

    handleDeleteUserSubmit(userId) {
        this.setState({
            showDeleteModal: false,
            users: this.state.users.filter(x => x.id !== userId),
            userToDeleteId: null,
            successMsg: this.props.t('user.msgDeletedSuccessfully')
        }, this.handleDismissAlert);
    }

    handleCreateUserModal() {
        this.setState({ showCreateModal: !this.state.showCreateModal });
    }

    handleCreateUserSubmit(newUser) {
        this.setState({
            users: [...this.state.users, newUser],
            showCreateModal: false,
            successMsg: this.props.t('user.msgCreatedSuccessfully')
        }, this.handleDismissAlert);
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;

        this.setState(prevState => ({
            currentUser: {
                ...prevState.currentUser,
                [name]: value
            }
        }));
    }

    renderUsersTable(users) {
        const { t } = this.props;
        const ADMIN = UserTable.UserType.admin;
        const USER = UserTable.UserType.user;

        //<Button className="d-inline pull-right p-0" variant="link" onClick={this.handleCreateUserModal}>
        //    {t('user.new')}
        //</Button>

        return (
            <Card className="mt-2">
                <Card.Header>
                    <h4 className="d-inline">DeveloperSite user list</h4>
                    <Button className="d-inline pull-right green-button" onClick={this.handleCreateUserModal}>
                        <i className="fa fa-plus" />
                    </Button>
                </Card.Header>
                <Card.Body>
                    <Table striped bordered hover responsive borderless className="table-sm">
                        <thead>
                            <tr className="table-title-style grey-background">
                                <th>{t('table.name')}</th>
                                <th>{t('table.email')}</th>
                                <th>{t('table.userType')}</th>
                                <th>{t('table.action')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {users.map((user) => (
                                <tr key={user.id}>
                                    <td>{user.name}</td>
                                    <td>{user.email}</td>
                                    {user.type === 0 ? <td>{ADMIN}</td> : <td>{USER}</td>}
                                    <td>
                                        <ButtonToolbar>
                                            <Button
                                                className="grey-button mr-1"
                                                onClick={() => this.handleDeleteUserModal(user.id)}
                                            >
                                                <i className="fa fa-trash-o" />
                                            </Button>
                                            <Button
                                                className="green-button"
                                                onClick={() => this.handleResendWelcomeUserEmail(user.email)}
                                            >
                                                <i className="fa fa-paper-plane" />
                                            </Button>
                                        </ButtonToolbar>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>

                </Card.Body>
            </Card>
        );
    }

    renderSpinner() {
        return (
            <div className="text-center mt-5">
                <Spinner animation="border" role="status" className="green-color">
                    <span className="sr-only">Loading...</span>
                </Spinner>
            </div>
        );
    }

    render() {
        const { isLoading, users, errorMsg, successMsg, showCreateModal, showDeleteModal, userToDeleteId, redirectToLogin } = this.state;

        let table = isLoading
            ? this.renderSpinner()
            : this.renderUsersTable(users);

        return (
            <>
                {redirectToLogin && <Redirect to={{ pathname: "/" }} /> }
                {showCreateModal && <CreateUserModal submit={this.handleCreateUserSubmit} close={this.handleCreateUserModal} />}
                {showDeleteModal && <DeleteUserModal submit={this.handleDeleteUserSubmit} close={this.handleDeleteUserModal} userId={userToDeleteId} />}
                {
                    errorMsg !== "" &&
                    <Alert variant={'danger'} className="mt-2">
                        {errorMsg}
                    </Alert>
                }
                {
                    successMsg !== "" &&
                    <Alert variant={'success'} className="mt-2">
                        {successMsg}
                    </Alert>
                }
                {table}
            </>
        );
    }
}

export default withTranslation()(UserTable);
import React from 'react';
import Nav from 'react-bootstrap/Nav';
import axios from 'axios';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

function clearSession() {
    clearServerSession();
}

function clearServerSession() {
    axios({
        method: 'POST',
        url: 'api/Login/Logout',
        headers: { 'Content-Type': 'application/json' }
    })
        .then(function (response) {
        })
        .catch(function (error) {
            console.log("ClearServerSession error: " + error)
        });
}

function Logout({ t }) {
    return (
        <Nav.Item>
            <Nav.Link as={Link} to="/" onClick={clearSession} replace>{t('logout')}</Nav.Link>
        </Nav.Item>
    );
}

export default withTranslation()(Logout);


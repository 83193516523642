import React from 'react';
import UserTable from './UserTable';
import NavBarAdmin from './NavBarAdmin';

function Admin() {
    return (
        <>
            <NavBarAdmin />
            <UserTable />
        </>
    );
}

export default Admin;

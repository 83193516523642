import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import ManualApi from './Api';
import ManualAppToApp from './AppToApp';
import ManualUser from './User';

class ManualTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            urlRedirect: "/",
            redirect: false,
            isLoading: false
        };
    }

    componentDidMount() {
        var _this = this;
        this.setState({ isLoading: true });

        axios({
            method: 'GET',
            url: 'api/User/IsUserActiveSession',
            headers: { 'Content-Type': 'application/json' }
        })
            .then(function (response) {
                _this.setState({ isLoading: false });
            })
            .catch(function (error) {
                _this.setState({ redirect: true });
            })
    }

    renderMainLogin() {
        const { t } = this.props;
        
        return (
            <Tab.Container id="left-tabs-example" defaultActiveKey="1">
                <Row>
                    <Col sm={3}>
                        <Nav variant="pills" className="flex-column">
                            <Nav.Item>
                                <Nav.Link eventKey="1">{t('manuals.apiIntegration')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="2">{t('manuals.appToAppIntegration')}</Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="3">{t('manuals.userLabel')}</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={9}>
                        <Tab.Content>
                            <Tab.Pane eventKey="1">
                                <ManualApi />
                            </Tab.Pane>
                            <Tab.Pane eventKey="2">
                                <ManualAppToApp />
                            </Tab.Pane>
                            <Tab.Pane eventKey="3">
                                <ManualUser />
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        );
    }

    renderRedirect() {
        return <Redirect to={{ pathname: this.state.urlRedirect }} />;
    }

    render() {
        const { redirect, isLoading } = this.state;

        return (
            <>
                {redirect && this.renderRedirect()}
                {isLoading === true &&
                    <div className="text-center mt-5">
                        <Spinner animation="border" role="status" className="green-color">
                            <span className="sr-only">Loading...</span>
                        </Spinner>
                    </div>
                }
                {isLoading === false &&
                    redirect === false &&
                    this.renderMainLogin()
                }
            </>
        );
    }
}

export default withTranslation()(ManualTabs);
import React from 'react';
import { withTranslation } from 'react-i18next';
import ListManual from './ListManual';
import "./manuals.css";

function User({ t }) {
    const manuals = [
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/EWsucetGSo5Dq1knm1rMdA8BTq41T5VqlKyCAYpAApF_ZQ?e=6fzxal",
            "title": t('manuals.user.vidSignerAndroid'),
            "description": t('manuals.user.vidSignerDesc')
        },
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/EVr8L5afsqNKvwSzvdXOh5EBbdzxh6qjWudh8xfhFdlyjQ?e=83t9Yo",
            "title": t('manuals.user.vidSigneriOS'),
            "description": t('manuals.user.vidSigneriOSDesc')
        },
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/EczpWIq473ZBk8RMbqOoBdsBKwe4lj1ExObNIhcIcv-iKQ?e=kppAx2",
            "title": t('manuals.user.vidSignerWindows'),
            "description": t('manuals.user.vidSignerWindowsDesc')
        },
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/Ec7AP-18UCxDpPH4mgIwO80BBK5FyBU3NhEHXfk_Qi4Jxg?e=vkktuP",
            "title": t('manuals.user.vidRemote'),
            "description": t('manuals.user.vidRemoteDesc')
        },
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/EadR_bg75BlKoG-_Hpgpso8BuTb5sCyme9HO15OhhO-drg?e=D4C9Ke",
            "title": t('manuals.user.centralized'),
            "description": t('manuals.user.centralizedDesc')
        },
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/EfXZrMxNrlVOmVFS4mnoU0wByo2ytKMcR7wqjseGEuT3hw?e=r8OEdM",
            "title": t('manuals.user.edelivery'),
            "description": t('manuals.user.edeliveryDesc')
        }
    ];

    return <ListManual links={manuals} />;
}

export default withTranslation()(User);
import i18n from 'i18next';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { withTranslation } from 'react-i18next';

function changeLanguage(lng) {
    i18n.changeLanguage(lng);
}

function DropdownLanguage({ t }) {
    return (
        <Dropdown className="float-right" >
            <Dropdown.Toggle variant="secondary" size="sm" className="grey-button">
                {i18n.languages[0] === 'en' ? t('englishLanguage') : t('spanishLanguage')}
            </Dropdown.Toggle>
            <Dropdown.Menu alignRight>
                <Dropdown.Item as="button" onClick={() => changeLanguage('en')}>{t('englishLanguage')}</Dropdown.Item>
                <Dropdown.Item as="button" onClick={() => changeLanguage('es')}>{t('spanishLanguage')}</Dropdown.Item>
            </Dropdown.Menu>
        </Dropdown>
    );
}

export default withTranslation()(DropdownLanguage);


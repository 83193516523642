import React, { Component } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import NavBarUser from './NavBarUser';

export class Layout extends Component {
    displayName = Layout.name

    render() {
        return (
            <Container>
                <Row>
                    <Col>
                        <NavBarUser />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {this.props.children}
                    </Col>
                </Row>
            </Container>
        );
    }
}

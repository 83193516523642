import React from 'react';
import { withTranslation } from 'react-i18next';
import ListManual from './ListManual';
import './manuals.css';

function AppToApp({ t }) {
    const manuals = [
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/Ea9zv6gtcxlOnOjzJU7kvvYBbiqAO354uBhbwZKZ6tLbmw?e=HkBtIO",
            "title": t('manuals.appToApp.windowsApp'),
            "description": t('manuals.appToApp.windowsAppInfo')
        },
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/EQKyco-94gZLr5Y6SSDx8FUBz0TnZcq1VmO8V7PNS9XTkw?e=rq7gey",
            "title": t('manuals.appToApp.iOSApp'),
            "description": t('manuals.appToApp.iOSAppInfo')
        },
        {
            "url": "https://vidsigner.sharepoint.com/:b:/s/PublicAssets/EfVqoumCiRlCqsMjRHN9bP0BN8Ud_hEin-DGNFA2R4VYkw?e=7q1YSy",
            "title": t('manuals.appToApp.androidApp'),
            "description": t('manuals.appToApp.androidAppInfo')
        }
    ];

    return <ListManual links={manuals} />;
}

export default withTranslation()(AppToApp);